//import {es2015Testing} from '../modules/es2015-playground';
//import {lodashTesting} from '../modules/lodash-playground';

$(document).ready(async function() {           
    configureDataLayerEvents();
    //lodashTesting();

    //working sample of a dynamic import :-D 
    //var {es2015Testing} = await import( /* webpackChunkName: "es2015" */ 'modules/es2015-playground' );
    //es2015Testing()
});

function configureDataLayerEvents() {

    //Slider link clicks        
    $('#home-slider').on('click', '.owl-item a', function (e) {
        var owlItem = $(this).closest('.owl-item');
        var item = $(this).closest('.item');
        var name = item.find('h3').html().trim();
        var position = $('#home-slider .owl-item').not('.cloned').index(owlItem) + 1;
        var id = 'slider-' + position;
        var url = extractUrl(item.css('background-image'));
        var bannerObj = { 'id': id, 'name': name, 'creative': url, 'position': position };
        onPromoClick(this, bannerObj);
    });

}